// 懒加载 api
import { lazy } from 'react';
// 状态仓库
import store from '../store';
// action 动作 -- 获取当前用户信息
import { getCurrentUser } from '../redux/selecters/platform.js';

/**
 * 路由配置 ----------------------------------------------------------------
 * @returns 
 */
function routerConfig() {
    const patientRouter = {
        // 首页默认加载的页面
        path: '/mainPage/patientsList',
        // 所使用的组件
        component: lazy(() => import("../pages/patientsList/index.js")),
        // 菜单名称文本
        label: 'PATIENT_LIST', // 受检人
        icon: 'student'
    }

    const examineRouter = {
        path: '/mainPage/examine-history',
        component: lazy(() => import("../pages/examine-history")),
        label: 'EXAMINE_LIST', // 检查记录
        icon: 'examine-history'
    }

    const organizationRouter = {
        path: '/mainPage/organization',
        component: lazy(() => import("../pages/organization/index.js")),
        label: 'ORGANIZATION', // 医疗机构
        icon: 'school'
    }

    const schoolRouter = {
        path: '/mainPage/school',
        component: lazy(() => import("../pages/organization/index.js")),
        label: 'SCHOOL', // 学校/科室管理
        icon: 'school'
    }

    const usersRouter = {
        path: '/mainPage/users',
        component: lazy(() => import("../pages/users/index.js")),
        label: 'USER_LIST', // 用户管理
        icon: 'user'
    }

    const projectsRouter = {
        path: '/mainPage/project',
        component: lazy(() => import("../pages/project/index.js")),
        label: 'PROJECT', // 项目管理
        icon: 'project'
    }

    const statisticRouter = {
        path: '/mainPage/statistic-analysis',
        component: lazy(() => import("../pages/statistic-analysis/index.tsx")),
        label: 'STATISTIC', // 数据统计
        icon: 'statistic'
    }

    const currentUserRouter = {
        path: '/mainPage/currentUser',
        component: lazy(() => import("../pages/currentUser/index.js")),
        label: 'CURRENT_USER', // 个人信息
        icon: 'person'
    }

    const appRouter = {
        path: '/mainPage/app-download',
        component: lazy(() => import("../pages/app-download")),
        label: 'APP_DOWNLOAD', // APP下载
        icon: 'download'
    }

    const deviceRouter = {
        path: '/mainPage/btCode',
        component: lazy(() => import("../pages/btCode/index.js")),
        label: 'BLUETOOTH_NO', // 蓝牙码管理
        icon: 'device'
    }

    const btCodeDownloadRouter = {
        path: '/mainPage/btCodeDownload',
        component: lazy(() => import("../pages/btCodeDownLoad/index.js")),
        label: 'BLUETOOTH_NO_DOWNLOAD', // 蓝牙码下载
        icon: 'device'
    }

    const systemInfoRouter = {
        path: '/mainPage/system-admn/about-system',
        component: lazy(() => import("../pages/system-admn/about-system/index.tsx")),
        label: 'ABOUT_SYSTEM', // 关于系统
        icon: 'about-system'
    }

    // 系统管理
    const systemAdmnRouter = {
        label: 'SYSTEM_ADMN', // 系统管理
        icon: 'system-admn',
        children: [
            systemInfoRouter,
            {
                path: '/mainPage/system-admn/report-setting',
                component: lazy(() => import("../pages/system-admn/report-set/index.tsx")),
                label: 'REPORT_SET', // 报告设置
                icon: 'report-set'
            }
        ]
    }

    const logsRouter = {
        path: '/mainPage/logs',
        component: lazy(() => import("../pages/logs/index.js")),
        label: 'LOGS', // 日志
        icon: 'logs'
    }

    // const actionWarehouseRouter = {
    //     path: '/mainPage/actionWarehouse',
    //     component: lazy(() => import("../pages/actionWarehouse/index.tsx")),
    //     label: 'ACTIONWAREHOUSE', // 动作库
    //     icon: 'exe'
    // }

    // const ExePrescriptionRouter = {
    //     path: '/mainPage/ExePrescription',
    //     component: lazy(() => import("../pages/ExePrescription/index.tsx")),
    //     label: 'EXEPRESCRIPTION', // 运动处方
    //     icon: 'prescription'
    // }

    // const AddExePrescriptionRouter = {
    //     path: '/mainPage/updateExePrescription',
    //     component: lazy(() => import("../pages/ExePrescription/updateExePrescription/index.tsx")),
    //     label: 'EXEPRESCRIPTION', // 运动处方
    //     icon: 'prescription',
    //     noRender: true,
    // }

    const webviewDemo = {
        path: '/mainPage/webview-demotest',
        component: lazy(() => import("@/pages/webview/download-quick-report/index.tsx")),
        label: 'demotest', // 运动处方
        icon: 'demotest',
        noRender: true,
    }

    // 系统管理员 -- 0
    const adminRouter = [organizationRouter, usersRouter, currentUserRouter, logsRouter, deviceRouter, appRouter, systemInfoRouter]
    // 机构管理员 -- 1
    const orgAdminRouter = [webviewDemo, schoolRouter, patientRouter, projectsRouter, examineRouter, usersRouter, statisticRouter, currentUserRouter, logsRouter, appRouter, systemAdmnRouter]
    // 机构医生 -- 2
    const orgDoctorRouter = [webviewDemo, schoolRouter, patientRouter, projectsRouter, examineRouter, statisticRouter, currentUserRouter, appRouter, systemInfoRouter]
    // 业务生产/研发 -- 3
    const produceRouter = [webviewDemo, btCodeDownloadRouter, systemInfoRouter, currentUserRouter];

    // 用户角色
    const { roleId: userRole } = getCurrentUser(store.getState());

    // 非注册版 或者 开发环境下 允许有动作库菜单
    if (!window.location.host.includes('www.spinenet.cn')) {
        // 插入指定位置
        // orgAdminRouter.splice(5, 0, actionWarehouseRouter);
    }

    // 权限分配
    const resRouter: any[] = []
    switch (userRole) {
        case 0: // 管理员
            //@ts-ignore
            resRouter.push(...adminRouter)
            break;
        case 1: // 机构管理员
            // username === "sz_admin" ? resRouter.push(...sz_adminRouter) :
            resRouter.push(...orgAdminRouter)
            break;
        case 2: // 机构医生
            resRouter.push(...orgDoctorRouter)
            break;
        case 3: // 生产/研发
            resRouter.push(...produceRouter)
            break;
    }

    // 返回
    return resRouter
}

export default routerConfig
